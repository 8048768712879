<template>
    <div class="modal-body">
        <div class="modal-body__header">
            <div class="modal-body__header-title">История согласования</div>
            <div class="modal-body__close" @click="$emit('close')">
                <img src="@/assets/images/icons/x.svg" alt="close">
            </div>
        </div>
        <div class="modal-body__content">
            <div class="modal-body__section" v-for="(section, index) in history" :key="index">
                <div class="modal-body__section-title">
                    <img
                        v-if="section.status === 'PROCESSING' || section.status === 'WAITING' || section.status === 'NOT_AVAILABLE'"
                        src="@/assets/images/icons/document-black.svg"
                        alt="check"
                        class="modal-body__section-icon">

                    <img
                        v-if="section.status === 'ACCEPTED'"
                        src="@/assets/images/icons/check-green-cricle.svg"
                        alt="check"
                        class="modal-body__section-icon">


                    <img
                        v-if="section.status === 'NOT_ACCEPTED'"
                        src="@/assets/images/icons/x-red-cricle.svg"
                        alt="check"
                        class="modal-body__section-icon">
                    <p>{{ section.title }}</p>
                </div>

                <div class="modal-body__section-cards">
                    <div class="modal-body__section-card" v-for="(element, i) in section.elements" :key="i">
                        <div class="modal-body__section-card-title">{{ element.title }}</div>
                        <div class="modal-body__section-card-row">
                            <p class="modal-body__section-card-text">{{ element.description }}</p>
                            <p class="modal-body__section-card-text">{{ element.time }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import responses from "@/api/responses";

const props = defineProps({
    id: [Number, String]
})

const history = ref([])
const getHistory = async () => {
    try {
        history.value = await responses.getHistory(props.id)
    } catch (error) {
        console.log(error)
    }
}

onMounted(() => {
    getHistory()
    document.body.style.overflow = 'hidden'
})
onUnmounted(() => {
    document.body.style.overflow = ''
})
</script>

<style scoped lang="scss">
.modal-body {
    overflow: hidden;
    border-radius: 16px;

    &__header {
        position: relative;
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-bottom: 1px solid $light-gray-1;
    }

    &__header-title {
        font-size: 16px;
        font-weight: 600;
    }

    &__close {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        img {
            width: 24px;
        }
    }

    &__content {
        height: 604px;
        padding-bottom: 24px;
        padding-top: 24px;
        overflow: auto;
    }

    &__section {
        padding: 0 24px;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__section-title {
        display: flex;
        align-items: center;

        img {
            width: 12px;
        }

        p {
            margin-left: 8px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    &__section-cards {
        margin-left: 16px;
        margin-top: 16px;
        padding-left: 16px;
        border-left: 1px solid $light-gray-1;
    }

    &__section-card {
        width: 100%;
        padding: 8px 12px;
        border-radius: 8px;
        background-color: $light;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    &__section-card-title {
        font-size: 14px;
    }

    &__section-card-row {
        width: 100%;
        margin-top: 4px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__section-card-text {
        max-width: 80%;
        color: $gray;
        font-size: 12px;
    }
}
</style>
