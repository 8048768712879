<template>
    <div class="actions">
        <h3 class="actions__title">Дейcтвия по отклику</h3>

        <div class="actions__btns">
            <div class="actions__transfer-decision" v-if="canDelay">
            <div
                @click="emit('transferDecision', {
                    id: action?.status_id
                })"
                class="actions__btn">
                <img src="@/assets/images/icons/watch.svg" alt="prev">
                Отложить решение
            </div>
        </div>
            <div
                v-for="action in actions" :key="action.status_id"
                v-show="action.btn !== 'BTN_BLUE_BORDERED'"
                @click="emit('next', {
                    id: action?.status_id
                })"
                class="actions__btn"
                :class="{'actions__btn_red': action.btn === 'BTN_RED_BORDERED'}">
                <img v-if="action.btn === 'BTN_BLUE'" src="@/assets/images/icons/prev-arrow.svg" alt="prev">
                <img v-if="action.btn === 'BTN_RED_BORDERED'" src="@/assets/images/icons/no.svg" alt="prev">
                {{ action.title }}
            </div>
            <!--            <div class="actions__btn">-->
            <!--                <img src="@/assets/images/icons/watch.svg" alt="watch">-->
            <!--                Отложить решение-->
            <!--            </div>-->
            <!--            <div class="actions__btn actions__btn_red">-->
            <!--                <img src="@/assets/images/icons/no.svg" alt="x">-->
            <!--                Отказать-->
            <!--            </div>-->
        </div>

        <div v-if="actions[actions.length - 1].btn === 'BTN_BLUE_BORDERED'" class="actions__footer">
            <VButton
                :label="actions[actions.length - 1].title"
                @click="emit('next', {
                    id: actions[actions.length - 1]?.status_id
                })"
                clickEffect/>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    actions: Array,
    canDelay: Boolean
})

const emit = defineEmits(['next','transferDecision'])
</script>

<style scoped lang="scss">
.actions {
    width: 100%;
    padding: 24px 16px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px #0000000A;

    &__title {
        margin-left: 0px;
        font-size: 14px;
        font-weight: 600;
    }

    &__transfer-decision {
        margin-top: 8px;
    }

    &__btns {
        margin-top: 16px;
    }

    &__btn {
        width: 100%;
        height: 32px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        color: $blue;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        user-select: none;

        img {
            margin-right: 3.5px;
        }

        &_red {
            color: $red;
        }

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    &__footer {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid $light-gray-1;
    }
}
</style>
