<template>
    <div class="resume">

        <div v-if="data.status_id <= 15 || (data.status_id === 24 && !data.files?.length)" class="resume__content">
            <div v-if="data.icon === 'lock'" class="resume__icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.6667 18.3359H8.33333C6.49238 18.3359 5 19.8283 5 21.6693V33.3359C5 35.1769 6.49238 36.6693 8.33333 36.6693H31.6667C33.5076 36.6693 35 35.1769 35 33.3359V21.6693C35 19.8283 33.5076 18.3359 31.6667 18.3359Z" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.6665 18.3359V11.6693C11.6665 9.45913 12.5445 7.33952 14.1073 5.77671C15.6701 4.21391 17.7897 3.33594 19.9998 3.33594C22.21 3.33594 24.3296 4.21391 25.8924 5.77671C27.4552 7.33952 28.3332 9.45913 28.3332 11.6693V18.3359" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div v-if="data.icon === 'clock'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0007 37.1693C29.2054 37.1693 36.6673 29.7073 36.6673 20.5026C36.6673 11.2979 29.2054 3.83594 20.0007 3.83594C10.7959 3.83594 3.33398 11.2979 3.33398 20.5026C3.33398 29.7073 10.7959 37.1693 20.0007 37.1693Z" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20 10.5V20.5L26.6667 23.8333" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            </div>
            <div v-if="data.icon === 'check'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0007 37.1693C29.2054 37.1693 36.6673 29.7073 36.6673 20.5026C36.6673 11.2979 29.2054 3.83594 20.0007 3.83594C10.7959 3.83594 3.33398 11.2979 3.33398 20.5026C3.33398 29.7073 10.7959 37.1693 20.0007 37.1693Z" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M27.4998 15.5469L17.883 25.1637L13.5117 20.7924" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div v-if="data.icon === 'user-x'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.667 35.5V32.1667C26.667 30.3986 25.9646 28.7029 24.7144 27.4526C23.4641 26.2024 21.7684 25.5 20.0003 25.5H8.33366C6.56555 25.5 4.86986 26.2024 3.61961 27.4526C2.36937 28.7029 1.66699 30.3986 1.66699 32.1667V35.5" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.1667 18.8333C17.8486 18.8333 20.8333 15.8486 20.8333 12.1667C20.8333 8.48477 17.8486 5.5 14.1667 5.5C10.4848 5.5 7.5 8.48477 7.5 12.1667C7.5 15.8486 10.4848 18.8333 14.1667 18.8333Z" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30 13.8359L38.3333 22.1693" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M38.3333 13.8359L30 22.1693" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <div v-if="data.icon" class="resume__title">
                {{ data.description }}
            </div>
        </div>
        <div v-if="data.status_id === 16" class="resume__upload">
            <div class="resume__title-text">Направление на медкомиссию</div>
            <div class="resume__upload-files">
                <VFileImage
                    v-for="(file, index) in uploadedFiles"
                    :key="index"
                    :file="file"
                    hideSize
                    deleteBtn
                    @delete="deleteFile(index)"/>

                <div class="resume__upload-files-btn">
                    <input @change="changeFile" type="file">
                    <img src="@/assets/images/icons/upload.svg" alt="upload">
                    Загрузить файл
                </div>
            </div>
            <p class="resume__field_error" v-if="showErrors && formErrors?.files">Заполните обязательное поле</p>


            <div class="resume__upload-header">
                <div class="resume__title-text">Памятка к направлению</div>
                <VCheckbox v-model="showMemo" @update:modelValue="changeFile"/>
            </div>
            <p class="resume__field_error" v-if="showErrors && formErrors?.description">Заполните обязательное поле</p>
            <VEditor
                v-if="showMemo"
                v-model="textMemo"
                @update:modelValue="changeFile"
                placeholder="Памятка к направлению*"
                class="resume__memo-editor"/>
        </div>
        <div
            v-if="
            data.status_id === 18 ||
            data.status_id === 19 ||
            data.status_id === 20 ||
            data.status_id === 21 ||
            data.status_id === 22 ||
            data.status_id === 23 ||
            (data.status_id === 24 && data.files?.length) ||
            data.status_id === 26"
            class="resume__wrapper-list">
            <div v-for="(item, index) in data.files" :key="index" class="resume__wrapper">
                <div v-if="index === 0" class="resume__title-text">Направление</div>
                <div v-if="index === 1" class="resume__title-text">Результаты медкомиссии</div>

                <div class="resume__files">
                    <VFileImage
                        v-for="file in item.data"
                        :key="file?.filename"
                        hideSize
                        :file="{file_name: file.filename, size: file.size, filepath: file.filepath}"
                    />
                </div>

                <div v-if="index === 0 && item.title && item.description" class="resume__title-text">{{ item.title }}</div>
                <p v-if="item.description" v-html="item.description" class="resume__text"></p>
            </div>
        </div>
        <div
            v-if="data.status_id === 17"
            class="resume__wrapper-list">
            <div :key="index" class="resume__wrapper">
                <div class="resume__title-text">Направление</div>

                <div class="resume__files">
                    <VFileImage
                        v-for="(file, index) in data.files.data"
                        :key="index"
                        hideSize
                        :file="{file_name: file.filename, size: file.size, filepath: file.filepath}"
                    />
                </div>

                <div class="resume__title-text">{{ data.files.title }}</div>
                <p class="resume__text" v-html="data.files.description"></p>
            </div>
        </div>
    </div>
</template>

<script setup>
import VFileImage from '@/components/UI/VFileImage'
import { ref } from "vue";
import VCheckbox from "@/components/UI/VCheckbox/index.vue";
import VEditor from "@/components/UI/VEditor";

const props = defineProps({
    data: Object,
    formErrors: Object,
    showErrors: Boolean
})

const emit = defineEmits(['addInfo'])

const showMemo = ref(false)
const textMemo = ref('')

const sendFile = async (body) => {

}

const maxImageSize = 10000000

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0';
    } else {
        let k = 1024;
        let dm = decimals < 0 ? 0 : decimals;
        let sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
        let i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}

const uploadedFiles = ref([])
const uploadedFilesMedical = ref([])
const changeFile = async (e) => {
    if (e.target) {
        if (e.target.files[0].size < maxImageSize) {
            const formData = new FormData();

            uploadedFiles.value.push({
                file_name: e.target.files[0].name,
                size: formatBytes(e.target.files[0].size)
            })

            uploadedFilesMedical.value.push(e.target.files[0])

            emit('addInfo', {
                files: uploadedFilesMedical.value,
                showMemo: showMemo.value,
                description: textMemo.value
            })
        }
    } else {
        emit('addInfo', {
            files: uploadedFilesMedical.value,
            showMemo: showMemo.value,
            description: textMemo.value
        })
    }
}

const deleteFile = (index) => {
    uploadedFiles.value.splice(index, 1)
    uploadedFilesMedical.value.splice(index, 1)
    emit('addInfo', {
            files: uploadedFilesMedical.value,
            showMemo: showMemo.value,
            description: textMemo.value
        })
}
</script>

<style scoped lang="scss">
.resume {
    width: 100%;
    height: 429px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__upload {
        padding: 24px;
        width: 100%;
        height: 100%;
    }

    &__upload-files {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 187px 187px 187px 187px;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
    }

    &__upload-files-btn {
        position: relative;
        width: 187px;
        height: 56px;
        padding: 0 23.5px;
        border-radius: 8px;
        background-color: $primary-blue;
        color: $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;

        img {
            width: 24px;
            margin-right: 8px;
        }

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__upload-header {
        width: 100%;
        margin-top: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__memo-editor {
        width: 100%;
        margin-top: 20px;
    }

    &__wrapper {
        &:not(:first-child) {
            padding-top: 24px;
            border-top: 1px solid $light-gray-1;
        }
    }

    &__wrapper-list {
        padding: 24px;
        width: 100%;
        height: 100%;
    }

    &__title-text {
        font-size: 16px;
        font-weight: 500;
    }

    &__text {
        display: block;
        padding-bottom: 24px;
        margin-top: 16px;
        font-size: 14px;
        font-weight: 400;
    }

    &__files {
        margin-top: 16px;
        padding-bottom: 24px;
        display: grid;
        grid-template-columns: 187px 187px 187px 187px;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
    }

    &__field {
        &_error {
            margin-top: 4px;
            font-size: 14px;
            color: $red;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__docs {
        width: 100%;
        height: 100%;
        padding: 22px 24px;
    }

    &__docs-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__docs-list {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 187px 187px 187px 187px;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
    }

    &__title {
        max-width: 266px;
        text-align: center;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
    }

    &__subtitle {
        margin-top: 12px;
        padding: 4px 8px;
        border-radius: 45px;
        display: flex;
        background-color: $light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        span {
            margin-left: 6px;
            font-weight: 500;
        }
    }
}
</style>
