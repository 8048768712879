<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>

        <h3 class="modal-body__title">Отказ соискателю</h3>
        <p class="modal-body__description">В случае отказа соискатель получит уведомление со следующим текстом:</p>


        <div class="modal-body__textarea">
            <p>Сообщение соискателю:</p>
            {{ textModal }}
        </div>

        <div class="modal-body__btns">
            <VButton
                label="Отказать соискателю"
                bg="#DD4646"
                bgHover="#D10E0E"
                color="#fff"
                class="modal-body__btn"
                :disabled="disabled"
                @click="refusal"/>

            <VButton
                label="Отменить"
                bg="#E4E7EE"
                bgHover="#BBC4CD"
                color="#1E3959"
                colorHover="#1E3959"
                class="modal-body__btn"
                @click="$emit('close')"/>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

defineProps({
    textModal: String
})

const emit = defineEmits(["closeVacancy"]);
const disabled = ref(false)

const text = ref('')
const refusal = () => {
    disabled.value = true
    emit('refusal', text.value)
}
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__title {
        font-size: 20px;
        font-weight: 600;
    }

    &__description {
        margin-top: 8px;
        font-size: 16px;
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__textarea {
        width: 100%;
        padding: 16px;
        margin-top: 16px;
        border-radius: 8px;
        font-size: 14px;
        background-color: $light;
        color: $gray;
        resize: none;
        text-align: left;

        p {
            margin-bottom: 4px;
            color: $dark-blue;
            font-size: 12px;
        }
    }

    &__btns {
        width: 100%;
        margin-top: 24px;

        &::v-deep(.v-button.v-button_disabled) {
            background-color: #FFC5C5 !important;
        }
    }

    &__btn {
        width: 100%;

        &:first-child {
            &:active {
                background-color: #A61F1F !important;
            }
        }

        &:last-child {
            margin-top: 12px;

            &:active {
                color: $dark !important;
                background-color: $gray !important;
            }
        }
    }
}
</style>
