<template>
    <div class="resume">
        <div v-if="data.icon === 'lock'" class="resume__content">
            <div v-if="data.icon === 'lock'" class="resume__icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M31.6667 18.3359H8.33333C6.49238 18.3359 5 19.8283 5 21.6693V33.3359C5 35.1769 6.49238 36.6693 8.33333 36.6693H31.6667C33.5076 36.6693 35 35.1769 35 33.3359V21.6693C35 19.8283 33.5076 18.3359 31.6667 18.3359Z"
                        stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M11.6665 18.3359V11.6693C11.6665 9.45913 12.5445 7.33952 14.1073 5.77671C15.6701 4.21391 17.7897 3.33594 19.9998 3.33594C22.21 3.33594 24.3296 4.21391 25.8924 5.77671C27.4552 7.33952 28.3332 9.45913 28.3332 11.6693V18.3359"
                        stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <div v-if="data.icon === 'clock'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.0007 37.1693C29.2054 37.1693 36.6673 29.7073 36.6673 20.5026C36.6673 11.2979 29.2054 3.83594 20.0007 3.83594C10.7959 3.83594 3.33398 11.2979 3.33398 20.5026C3.33398 29.7073 10.7959 37.1693 20.0007 37.1693Z"
                        stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20 10.5V20.5L26.6667 23.8333" stroke="#8F9CAC" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>

            </div>
            <div v-if="data.icon === 'check'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.0007 37.1693C29.2054 37.1693 36.6673 29.7073 36.6673 20.5026C36.6673 11.2979 29.2054 3.83594 20.0007 3.83594C10.7959 3.83594 3.33398 11.2979 3.33398 20.5026C3.33398 29.7073 10.7959 37.1693 20.0007 37.1693Z"
                        stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M27.4998 15.5469L17.883 25.1637L13.5117 20.7924" stroke="#8F9CAC" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
            <div v-if="data.icon === 'user-x'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M26.667 35.5V32.1667C26.667 30.3986 25.9646 28.7029 24.7144 27.4526C23.4641 26.2024 21.7684 25.5 20.0003 25.5H8.33366C6.56555 25.5 4.86986 26.2024 3.61961 27.4526C2.36937 28.7029 1.66699 30.3986 1.66699 32.1667V35.5"
                        stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M14.1667 18.8333C17.8486 18.8333 20.8333 15.8486 20.8333 12.1667C20.8333 8.48477 17.8486 5.5 14.1667 5.5C10.4848 5.5 7.5 8.48477 7.5 12.1667C7.5 15.8486 10.4848 18.8333 14.1667 18.8333Z"
                        stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M30 13.8359L38.3333 22.1693" stroke="#8F9CAC" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M38.3333 13.8359L30 22.1693" stroke="#8F9CAC" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>

            <div v-if="data.icon" class="resume__title">
                {{ data.description }}
            </div>
        </div>

        <div v-if="data.status_id === 30" class="resume__content">
            <div class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M26.668 35.5V32.1667C26.668 30.3986 25.9656 28.7029 24.7153 27.4526C23.4651 26.2024 21.7694 25.5 20.0013 25.5H8.33464C6.56653 25.5 4.87083 26.2024 3.62059 27.4526C2.37035 28.7029 1.66797 30.3986 1.66797 32.1667V35.5"
                        stroke="#8F9CAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M14.1667 18.8333C17.8486 18.8333 20.8333 15.8486 20.8333 12.1667C20.8333 8.48477 17.8486 5.5 14.1667 5.5C10.4848 5.5 7.5 8.48477 7.5 12.1667C7.5 15.8486 10.4848 18.8333 14.1667 18.8333Z"
                        stroke="#8F9CAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M28.3359 18.8333L31.6693 22.1667L38.3359 15.5" stroke="#8F9CAC" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>

            <div class="resume__title">
                {{ data.description }}
            </div>
        </div>

        <div v-if="data.status_id === 26 || (data.status_id === 27 && data.action[data.action?.length - 1].status_id !== 29) || data.status_id === 28 || data.status_id === 31"
            class="resume__form">
            <div class="resume__form-top">
                <div class="resume__form-title">Свяжитесь с соискателем</div>
                <div class="resume__form-subtitle">Для согласования даты прибытия к месту работы
                </div>

                <div class="resume__form-top-row">
                    <div @click="emit('openChat')" class="resume__form-top-btn cursor_pointer">Чат с соискателем</div>

                    <div class="resume__form-top-tel">
                        <div class="resume__form-top-tel-label">Телефон</div>
                        <div class="resume__form-top-tel-num">{{ baseInfo?.[3].value }}</div>
                    </div>
                </div>
            </div>

            <div class="resume__form-bottom">
                <div class="resume__form-bottom-row">
                    <div class="resume__form-title">Купить билеты</div>

                    <VCheckbox v-model="showFields" @update:modelValue="changeCheckbox" :disabled="disabledFields" />
                </div>
                <div v-if="showFields" class="resume__form-subtitle">Заполните информацию в полях для ответственного за
                    покупку билетов
                </div>

                <div v-if="showFields" class="resume__form-bottom-form">
                    <div class="resume__form-bottom-group">
                        <div>
                            <VCustomInput :disabled="disabledFields" class="resume__form-bottom-inp"
                                :class="{ 'resume__form-bottom-inp_disabled': disabledFields }"
                                :placeholder="'Город вылета*'" v-model="form.city" />
                            <p class="resume__form-bottom-inp_error" v-if="showErrors && formErrors.city">{{
            formErrors.city }}</p>
                        </div>
                        <div>
                            <VCustomInput :disabled="disabledFields" class="resume__form-bottom-inp"
                                :class="{ 'resume__form-bottom-inp_disabled': disabledFields }" placeholder="Дата вылета*"
                                v-mask="'00.00.0000'" v-model="form.date" />
                            <p class="resume__form-bottom-inp_error" v-if="showErrors && formErrors.date">{{
            formErrors.date }}</p>
                        </div>



                    </div>

                    <div class="resume__form-bottom-upload">
                        <div v-if="file" class="resume__form-bottom-upload-wrapper">
                            <VFile v-if="file && !data?.passport?.passport" class="resume__form-bottom-upload-file"
                                :file="file" :deleteBtn="true" @delete="onDeleteTicket" />
                            <VFile v-else-if="data?.passport?.passport" class="resume__form-bottom-upload-file"
                                :file="file" />
                        </div>
                        <div v-if="!file" class="resume__upload-files-btn">
                            <input @change="changeFile" type="file">
                            <img src="@/assets/images/icons/upload.svg" alt="upload">
                            Загрузите паспорт
                        </div>
                        <p class="resume__form-bottom-inp_error" v-if="showErrors && formErrors.file">{{ formErrors.file
                            }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(data.status_id === 27 && data.action[data.action?.length - 1].status_id === 29)"
            class="resume__form-me">
            <div class="resume__form-me-top">
                <div class="resume__form-title">Информация по кандидату</div>

                <div class="resume__form-me-row">
                    <VCustomInput disabled class="resume__form-me-inp resume__form-bottom-inp_disabled"
                        placeholder="Город вылета*" v-model="data.departure_city" />
                        <p class="resume__form-bottom-inp_error" v-if="showErrors && formErrors.city">{{
                            formErrors.city }}</p>

                    <VCustomInput disabled class="resume__form-me-inp resume__form-bottom-inp_disabled"
                        placeholder="Дата вылета*" v-model="data.departure_date" />
                        <p class="resume__form-bottom-inp_error" v-if="showErrors && formErrors.date">{{
                            formErrors.date }}</p>
                </div>

                <VFileImage class="resume__form-me-passport" hideSize :file="data.passport?.passport[0]" />
            </div>

            <div class="resume__form-me-tickets">
                <div class="resume__form-title">Загрузите билеты</div>
                <div class="resume__form-me-tickets-upload">
                    <VFile v-for="ticketsFile in ticketsFiles" class="resume__form-bottom-upload-file"
                        :file="ticketsFile" />
                    <div class="resume__upload-files-btn">
                        <input @change="addTickets" type="file">
                        <img src="@/assets/images/icons/upload.svg" alt="upload">
                        Загрузить файл
                    </div>
                </div>
                <p class="resume__form-bottom-inp_error" v-if="showErrors && formErrors?.ticketsFiles">{{ formErrors.ticketsFiles
                            }}</p>
            </div>

            <div class="resume__form-me-bottom">
                <div class="resume__form-title">Срок прибытия кандидата</div>
                <div class="resume__form-me-row">
                    <div>
                        <VCustomInput :disabled="disabledFields" class="resume__form-me-inp"
                            placeholder="Дата прибытия*" v-mask="'00.00.0000'" v-model="form.dateTickets" />
                        <p class="resume__form-bottom-inp_error" v-if="showErrors && formErrors.dateTickets">{{
            formErrors.dateTickets }}</p>
                    </div>

                    <div>
                        <VCustomInput :disabled="disabledFields" class="resume__form-me-inp"
                            placeholder="Время прибытия*" v-mask="'00:00'" v-model="form.timeTickets" />
                        <p class="resume__form-bottom-inp_error" v-if="showErrors && formErrors.timeTickets">{{
            formErrors.timeTickets }}</p>
                    </div>



                </div>
            </div>
        </div>

        <div v-if="data.status_id === 29" class="resume__tickets">
            <div v-if="data.arrival_place_img?.length" class="resume__tickets-images">
                <div class="resume__tickets-title">Фотографии места прибытия</div>
                <div class="resume__tickets-images-list">
                    <VFileImage v-for="(file, index) in placeImgs" :key="index" :file="file" />
                </div>
            </div>

            <div v-if="data.route_reminder" class="resume__tickets-reminder">
                <div class="resume__tickets-title">Памятка к маршруту</div>
                <div v-html="data.route_reminder" class="resume__tickets-reminder-text"></div>
            </div>

            <div class="resume__tickets-tickets">
                <div class="resume__tickets-title">{{ data.tickets?.title }}</div>

                <div class="resume__tickets-tickets-files">
                    <VFileImage v-for="(file, index) in tickets" :key="index" :file="file" />
                </div>
            </div>

            <div class="resume__tickets-arrival">
                <div class="resume__tickets-title">Срок прибытия кандидата</div>

                <div class="resume__form-bottom-group">
                    <VCustomInput disabled class="resume__form-bottom-inp resume__form-bottom-inp_disabled"
                        placeholder="Дата прибытия" v-model="data.arrival_date" />

                    <VCustomInput disabled class="resume__form-bottom-inp resume__form-bottom-inp_disabled"
                        placeholder="Время прибытия" v-model="data.arrival_time" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import VFileImage from '@/components/UI/VFileImage'
import { onMounted, ref, watch } from "vue";
import VCheckbox from "../../UI/VCheckbox/index.vue";
import VCustomInput from "../../UI/VCustomInput/index.vue";

const props = defineProps({
    data: Object,
    baseInfo: Array,
    showErrors: Boolean
})
const emit = defineEmits(['addInfo', 'changeCheckbox', 'openChat', 'validateFields'])

const showFields = ref(true)
const form = ref({
    city: '',
    date: '',
    dateTickets: '',
    timeTickets: '',
})

const formErrors = ref({
    city: '',
    date: '',
    file: '',
    dateTickets: '',
    timeTickets: '',
    ticketsFiles: ''
})

const disabledFields = ref(false)
const uploadedFiles = ref(null)
const file = ref(null)

const maxImageSize = 10000000
const ticketsFiles = ref([])
const ticketsFilesSend = ref([])

const validateTickets = () => {
        if (ticketsFiles.value?.length < 1) {
            formErrors.value.ticketsFiles = 'Заполните обязательное поле'
        } else {
            formErrors.value.ticketsFiles = ''
        }
}
const validateFields = () => {
    if(showFields.value){
        if (!form.value.city || form.value.city?.length < 1) {
        formErrors.value.city = 'Заполните обязательное поле'
    } else {
        formErrors.value.city = ''
    }
    if (!form.value.date || form.value.date?.length < 6) {
        formErrors.value.date = 'Заполните обязательное поле'
    } else {
        formErrors.value.date = ''
    }
    }
    if ((props.data.status_id === 27 && props.data.action[props.data.action?.length - 1].status_id === 29)) {
        if (!form.value.dateTickets || form.value.dateTickets.length < 6) {
            formErrors.value.dateTickets = 'Заполните обязательное поле'
        } else {
            formErrors.value.dateTickets = ''
        }
        if (!form.value.timeTickets || form.value.timeTickets.length < 4) {
            formErrors.value.timeTickets = 'Заполните обязательное поле'
        } else {
            formErrors.value.timeTickets = ''
        }
        validateTickets()
    }else {
        if(showFields.value){
            if (!file.value) {
                formErrors.value.file = 'Необходимо загрузить паспорт'
            } else {
                formErrors.value.file = ''
            }
        }
    }
    return !Object.values(formErrors.value).some((val) => !!val)
}


const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0';
    } else {
        let k = 1024;
        let dm = decimals < 0 ? 0 : decimals;
        let sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
        let i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
const changeFile = async (e) => {
    if (e.target.files[0].size < maxImageSize) {
        const formData = new FormData();
        uploadedFiles.value = e.target.files[0]

        file.value = {
            filename: e.target.files[0].name,
            size: formatBytes(e.target.files[0].size)
        }

        emit('addInfo', {
            departure_city: form.value.city,
            departure_date: form.value.date,
            file: uploadedFiles.value
        })
    }
}

const onDeleteTicket = () => {
    file.value = null
}
const addTickets = async (e) => {
    if (e.target.files[0].size < maxImageSize) {
        const formData = new FormData();
        ticketsFilesSend.value.push(e.target.files[0])
        ticketsFiles.value.push({
            filename: e.target.files[0].name,
            size: formatBytes(e.target.files[0].size)
        })

        emit('addTickets', {
            files: ticketsFilesSend.value,
            date: form.value.dateTickets,
            time: form.value.timeTickets
        })
    }
    validateTickets()
}

watch(form.value, () => {
    emit('addInfo', {
        departure_city: form.value.city,
        departure_date: form.value.date,
        file: uploadedFiles.value
    })

    emit('addTickets', {
        files: ticketsFilesSend.value,
        date: form.value.dateTickets,
        time: form.value.timeTickets
    })
    validateFields()
    emit('validateFields', formErrors.value)


})

watch(file, () => validateFields())
const changeCheckbox = () => {
    emit('changeCheckbox', showFields.value)
}

const disabledBlock = () => {
    if ((props.data.status_id === 27 && props.data.action[props.data.action?.length - 1].status_id !== 29) || props.data.status_id === 31) {
        disabledFields.value = true
        showFields.value = true

        if (props.data.departure_city) {
            form.value.city = props.data.departure_city
        }
        if (props.data.departure_date) {
            form.value.date = props.data.departure_date
        }
        if (props.data.passport?.passport) {
            file.value = {
                filename: props.data.passport?.title,
                size: 0,
                filepath: props.data.passport?.passport[0].filepath
            }
        }
    }
    if (props.data.status_id === 31) {
        file.value = {
            filename: props.data.arrival_place_img?.[0].original_name,
            size: props.data.arrival_place_img?.[0].size,
            filepath: props.data.arrival_place_img?.[0].path
        }
    }
}

const tickets = ref([])
const placeImgs = ref([])

watch(() => props.data, (first, second) => {
    disabledBlock()
})

onMounted(() => {
    disabledBlock()

    props.data.tickets?.tickets?.forEach((item) => {
        tickets.value.push({
            file_name: item.filename,
            filepath: item.filepath,
            size: 0
        })
    })
    props.data.arrival_place_img?.forEach((item) => {
        placeImgs.value.push({
            file_name: item.original_name,
            filepath: item.path,
            size: `${item.size}МБ`
        })
    })

    showFields.value = props.data.available_tickets

    form.value.city = props.data.departure_city
    form.value.date = props.data.departure_date
})
</script>

<style scoped lang="scss">
.resume {
    width: 100%;
    min-height: 429px;
    display: flex;
    justify-content: center;

    &__tickets {
        width: 100%;
        height: 100%;
        padding: 24px;
    }

    &__tickets-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__tickets-images {
        margin-bottom: 24px;
    }

    &__tickets-reminder-text {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%
    }

    &__tickets-images-list {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 12px;
    }

    &__tickets-tickets {
        margin-top: 24px;
    }

    &__tickets-tickets {
        display: grid;
        grid-template-columns: 188px;
    }

    &__tickets-tickets-files {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 188px 188px 188px 188px;
        grid-column-gap: 12px;
        grid-row-gap: 12px;

        .v-file {
            width: 100%;
        }
    }

    &__form {
        width: 100%;
        height: 100%;
        padding: 24px;
    }

    &__form-me {
        width: 100%;
        min-height: 429px;
        height: 100%;
        padding: 24px;
    }

    &__form-me-top {}

    &__form-me-row {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 16px;
        grid-column-gap: 16px;
    }

    &__form-me-inp {
        width: 100%;
    }

    &__form-me-passport {
        margin-top: 16px;
        width: 188px;
    }

    &__form-me-tickets {
        margin-top: 24px;
    }

    &__form-me-tickets-upload {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 12px;
        grid-column-gap: 12px;
    }

    &__form-me-bottom {
        margin-top: 24px;
    }

    &__form-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__form-subtitle {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $gray;
    }

    &__form-top-row {
        margin-top: 24px;
        display: flex;
        align-items: center;
    }

    &__form-top-btn {
        width: 200px;
        height: 48px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-blue;
        color: $blue;
        font-size: 16px;
        font-weight: 400;
    }

    &__form-top-tel {
        height: 30px;
        margin-left: 24px;
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        border-left: 1px solid $light-gray-1;
    }

    &__form-top-tel-label {
        margin-top: -3px;
        color: $gray;
        font-size: 12px;
        font-weight: 400;
    }

    &__form-top-tel-num {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 400;
    }

    &__form-bottom {
        margin-top: 28.5px;
    }

    &__form-bottom-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__form-bottom-form {
        margin-top: 16px;
    }

    &__form-bottom-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
    }

    &__form-bottom-inp {
        width: auto;

        &_disabled {
            opacity: .5;
        }

        &_error {
            margin-top: 4px;
            font-size: 14px;
            color: $red;
        }
    }

    &__form-bottom-upload {
        margin-top: 16px;
    }

    &__form-bottom-upload-wrapper {
        display: flex;
    }

    &__form-bottom-upload-file {
        width: 188px;
        height: 56px;

        &::v-deep(.v-file__content) {
            max-width: 100px !important;
        }
    }

    &__form-bottom-upload-delete {
        position: absolute;
    }

    &__upload-files-btn {
        position: relative;
        width: 210px;
        height: 56px;
        padding: 0 23.5px;
        border-radius: 8px;
        background-color: $primary-blue;
        color: $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;

        img {
            width: 24px;
            margin-right: 8px;
        }

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__title {
        max-width: 266px;
        text-align: center;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
    }

    &__subtitle {
        margin-top: 12px;
        padding: 4px 8px;
        border-radius: 45px;
        display: flex;
        background-color: $light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        span {
            margin-left: 6px;
            font-weight: 500;
        }
    }

    &__tickets-arrival {
        margin-top: 24px;
    }

    &__form-bottom-group {
        margin-top: 16px;
    }
}

.cursor {
    &_pointer {
        cursor: pointer;
    }
}
</style>
