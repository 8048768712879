<template>
    <div class="info-menu">
        <h3 class="info-menu__title">Информация</h3>
        <div class="info-menu__btns">
            <div class="info-menu__btn" @click="$emit('openChat')">
                <img src="@/assets/images/icons/chat-blue.svg" :class="['info-menu__btn-icon', {'info-menu__btn-icon_new-message': props?.isNewMessage}]" alt="pencil">
                <p class="info-menu__btn-label">Чаты</p>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps(['isNewMessage'])
</script>

<style scoped lang="scss">
.info-menu {
    width: 256px;
    padding: 24px 16px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px #0000000A;


    &__title {
        margin-left: 0px;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
    }

    &__btns {
        margin-top: 16px;

        &_last {
            margin-top: 16px;
            padding-top: 16px;
            border-top: 1px solid $light-gray-1;
        }
    }

    &__btn {
        width: 100%;
        padding: 0 8px;
        height: 32px;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        transition: .3s;
        cursor: pointer;
        text-decoration: none;
        user-select: none;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &:hover {
            background-color: $light-gray-1;
        }

        &:active {
            background-color: $light-gray-2;
        }
    }

    &__btn-icon {
        width: 24px;
        height: 24px;
        object-fit: contain;

        &_new-message {

            &::after{
            content: ' ';
            position: absolute;
            top: 6px;
            right: 6px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            z-index: +1;
            background-color: $red;
            }
            }
    }

    &__btn-label {
        margin-left: 8px;
        color: $blue;
        font-size: 14px;
        font-weight: 400;
    }
}
</style>
