<template>
    <div class="questionnaire">
        <div v-if="fields?.length">
            <div class="questionnaire__main" v-for="(field, index) in fieldGrouping" v-show="fieldGrouping.length"
                :key="field.title">
                <div v-for="group in field" class="questionnaire__main-wrapper" :key="group?.title"
                    :class="{ 'questionnaire__main-wrapper_file': group.type === 'FILE' }">
                    <div class="questionnaire__title">{{ group.title }}</div>
                    <div class="questionnaire__main-content">
                        <div class="questionnaire__main-fields" v-if="group?.fields?.length">
                            <div v-for="(groupField, groupIndex) in group.fields" :key="groupIndex"
                                class="questionnaire__main-fields-wrapper" :class="{
                'questionnaire__main-fields-wrapper_file': groupField.type === 'FILE',
                'questionnaire__main-fields-wrapper_multiple': groupField.type === 'MULTIPLE_FIELD' || groupField.type === 'FIELD_MULTIPLE_FIELDS',
                'questionnaire__main-fields-wrapper_experience': groupField.type === 'EXPERIENCE',
            }">

                                <div class="questionnaire__main-field"
                                    v-if="groupField.type === 'FIELD_TEXT' || groupField.type === 'FIELD_SINGLE_SELECT' || groupField.type === 'FIELD_DATE' || groupField.type === 'FIELD_LIST'">
                                    <div class="questionnaire__main-field-label">{{ groupField.title }}</div>
                                    <div class="questionnaire__main-field-value" v-if="groupField.my_value?.length">{{
            groupField.my_value[0]
        }}
                                    </div>
                                    <div class="questionnaire__main-field-value" v-else>Не указано</div>
                                </div>

                                <div class="questionnaire__documents-item" v-if="groupField.type === 'FILE'">
                                    <div class="questionnaire__documents-item-left">
                                        <img src="@/assets/images/icons/document-black.svg" alt="document"
                                            class="questionnaire__documents-item-icon">
                                        <div class="questionnaire__documents-item-content">
                                            <div class="questionnaire__documents-item-name">{{ groupField.title }}</div>
                                            <div class="questionnaire__documents-item-size" v-if="groupField.value">{{
            groupField.value.size
        }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="questionnaire__documents-item-right" v-if="groupField.value">
                                        <div class="questionnaire__documents-item-count">{{
            groupField.value.count
        }}
                                        </div>
                                        <div @click="downloadFile(groupField.value.url)"
                                            class="questionnaire__documents-item-download">
                                            <img src="@/assets/images/icons/download-blue.svg" alt="vakhta-file">
                                        </div>
                                    </div>
                                </div>

                                <div v-if="groupField.type === 'FIELD_FILE'"
                                    class="questionnaire__documents-field-file">
                                    <VFile v-for="document in groupField.my_files?.files" :key="document?.filename"
                                        hideSize :file="{
            file_name: document.filename,
            size: `1 файл`,
            filepath: document.download_link
        }" />
                                </div>

                                <div class="questionnaire__multiple-field"
                                    v-if="groupField.type === 'MULTIPLE_FIELD' || groupField.type === 'FIELD_MULTIPLE_FIELDS'">
                                    <div class="questionnaire__title">{{ groupField?.title }}</div>
                                    <div class="questionnaire__multiple-field-blocks">
                                        <div class="questionnaire__multiple-field-block"
                                            v-for="(mySubfieldValue, keyMySubfieldValue) in groupField?.my_subfields_values" :key="mySubfieldValue">
                                            <div class="questionnaire__multiple-field-item"
                                                v-for="(subfield, key) in groupField.subfields" :key="key">
                                                <div class="questionnaire__multiple-field-label">{{ subfield?.title }}
                                                </div>
                                                <div class="questionnaire__multiple-field-value">
                                                    {{ mySubfieldValue?.[key]?.value?.[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="questionnaire__work-experience" v-if="groupField.type === 'EXPERIENCE'">
                                    <div class="questionnaire__title">{{ groupField.title }}</div>

                                    <div class="questionnaire__work-experience-list">
                                        <div class="questionnaire__work-experience-item" v-if="groupField.value.length"
                                            v-for="(experience, index) in groupField.value" :key="index">
                                            <div class="questionnaire__work-experience-gray">{{
            experience.COMPANY_TITLE
        }}
                                            </div>
                                            <div class="questionnaire__work-experience-title">{{
                experience.SPECIALITY
            }}
                                            </div>
                                            <div class="questionnaire__work-experience-gray">
                                                {{ dateStart(experience.START_WORK) }}
                                                -
                                                {{
            experience.WORKING_NOW == 1 ? 'по наст. время' :
                dateEnd(experience.END_WORK)
        }}
                                                {{
                calculateInterval(experience.WORKING_NOW, experience.START_WORK,
                    experience.END_WORK)
            }}
                                            </div>
                                            <div class="questionnaire__work-experience-description">{{
                experience.FUNCTIONS
            }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import VFile from '@/components/UI/VFileImage'
import moment from "moment";
import api from "@/api/api";

const props = defineProps({
    fields: {
        type: Array,
        required: true
    }
})

const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

const dateStart = computed(() => {
    return val => {
        return `${months[moment(val, 'DD.MM.YYYY').month()]} ${moment(val, 'DD.MM.YYYY').year()}`
    }
})
const dateEnd = computed(() => {
    return val => {
        return `${months[moment(val, 'DD.MM.YYYY').month()]} ${moment(val, 'DD.MM.YYYY').year()}`
    }
})
const calculateInterval = computed((workingNow, start, end) => {
    return val => {
        const startDate = moment(workingNow == 1 ? new Date() : start)
        const duration = moment.duration(moment(end).diff(startDate));
        const years = duration.asYears()
        return ''
    }
})

const fieldGrouping = computed(() => {
    const fields = props.fields
    let group = []
    if (!fields?.length) return
    fields.forEach((field, fieldIndex) => {
        group.push([]);
        let groupLastIndex = group.length - 1;
        field.data.forEach((item) => {
            if (!group[groupLastIndex].length) {
                group[groupLastIndex].push({
                    type: item.type,
                    title: field.title,
                    fields: []
                })
                group[groupLastIndex][0].fields.push(item)
            } else {
                let groupElementLastIndex = group[groupLastIndex].length - 1;
                if (group[groupLastIndex][groupElementLastIndex].type === item.type) {
                    group[groupLastIndex][groupElementLastIndex].fields.push(item)
                } else {
                    group[groupLastIndex].push({
                        type: item.type,
                        title: groupLastIndex < 1 ? field.title : '',
                        fields: []
                    })
                    group[groupLastIndex][groupElementLastIndex + 1].fields.push(item)
                }
            }
        })
    })
    return group
})

const downloadFile = async (fileUrl) => {
    const response = await api.get(fileUrl, { responseType: 'blob' });
    let link = document.createElement('a');
    link.href = URL.createObjectURL(response)
    link.download = 'vakhta-document'
    link.click();
}

</script>

<style scoped lang="scss">
.questionnaire {
    padding: 0 24px 24px 24px;

    &__main {
        padding-bottom: 24px;

        &:not(:first-child) {
            padding-top: 24px;
            border-top: 1px solid $light-gray-1;
        }
    }

    &__main:first-child &__main-wrapper:not(:first-child) &__title {
        display: none;
    }


    &__main-wrapper_file &__main-content {
        margin-left: 0px;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
    }

    &__main-wrapper_file &__title {
        margin-bottom: 16px;
    }


    &__main-fields &__title {
        font-size: 14px;
    }

    &__main-content {
        margin-left: 10px;
    }

    &__main-fields {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
    }

    &__fields-list {
        padding-bottom: 24px;
        display: flex;
        flex-wrap: wrap;
    }

    &__main-fields-wrapper {
        margin-left: -44px;
        display: flex;
        flex-wrap: wrap;

        &_file {
            margin-left: 10px;
            .questionnaire__documents-item {
                margin-top: 15px !important;
                margin-left: 0;
            }
        }

        &_multiple {
            width: 100%;
            margin-left: 0;
        }

        &_experience {
            width: 100%;

            margin-left: 0;
        }
    }


    &__main-wrapper:not(:first-child) &__main-fields-wrapper_multiple {
        margin-top: 24px;
        border-top: 1px solid $light-gray-1;
    }

    &__main-wrapper:not(:first-child) &__main-fields-wrapper_experience {
        margin-top: 24px;
        border-top: 1px solid $light-gray-1;
    }

    &__main-field {
        margin-left: 44px;
        margin-top: 16px;
        overflow: hidden;

        &:nth-child(3n+1) {
            width: 193px;
        }

        &:nth-child(3n+2) {
            width: 130px;
        }

        &:nth-child(3n+3) {
            width: 154px;
        }
    }

    &__main-field-label {
        width: 80%;
        color: $gray;
        font-size: 12px;

    }

    &__main-field-value {
        margin-top: 4px;
        width: 100%;
        font-size: 14px;
    }

    &__work-experience {
        margin-top: 24px;
    }

    &__work-experience &__title {
        display: block !important;
    }

    &__work-experience-list {
        margin-top: 16px;
    }

    &__work-experience-item {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__work-experience-gray {
        color: $gray;
        font-size: 12px;
    }

    &__work-experience-title {
        margin: 4px 0 8px 0;
        font-size: 14px;
        font-weight: 500;
    }

    &__work-experience-description {
        margin-top: 8px;
        font-size: 14px;
        line-height: 17px;
    }

    &__documents {
        margin-top: 24px;
    }

    &__documents-list {
        margin-top: 1px;
        margin-left: -15px;
        display: flex;
        flex-wrap: wrap;
    }

    &__documents-field-file {
        margin-top: 16px;
        margin-left: 44px;
        display: grid;
        grid-template-columns: 213px 213px 213px;
        grid-row-gap: 12px;
        grid-column-gap: 12px;
    }

    &__documents-item {
        width: 213px;
        height: 70px;
        padding: 0 12px;
        margin-left: 15px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $light;

        &_field-file {
            margin-top: 16px;
            margin-left: 44px;
        }
    }

    &__documents-item-left {
        width: 90%;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    &__documents-item-right {
        display: flex;
        align-items: center;
    }

    &__documents-item-count {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        margin-bottom: 4px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue;
        color: #fff;
        font-size: 11px;
    }

    &__documents-item-download {
        cursor: pointer;
    }

    &__documents-item-content {
        width: 100%;
        margin-left: 12px;
    }

    &__documents-item-name {
        width: 80%;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__documents-item-size {
        margin-top: 4px;
        color: $gray;
        font-size: 12px;
    }

    &__multiple-field {
        width: 100%;
        margin-top: 16px;
    }

    &__multiple-field-blocks {
        margin-top: 16px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;

    }

    &__multiple-field-block {
        padding: 16px;
        border-radius: 8px;
        background-color: $light;
    }

    &__multiple-field-item {
        width: 100%;
        display: flex;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__multiple-field-label {
        width: 140px;
        color: $gray;
        font-size: 12px;
    }

    &__multiple-field-value {
        width: 143px;
        font-size: 14px;
    }

    &__empty {
        width: 100%;
        height: 460px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__empty-text {
        max-width: 247px;
        margin-top: 16px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }
}
</style>
