<template>
  <div class="transfer-decision">
    <div class="transfer-decision__close">
      <img src="@/assets/images/icons/x.svg" alt="close" @click="$emit('close')" />
    </div>
    <div class="transfer-decision__head">
      <h3 class="transfer-decision__title">Перенос решения</h3>
      <p class="transfer-decision__subtitle">Перенесите срок дедлайна по решению задачи на новую дату</p>
    </div>
    <div class="transfer-decision__main">
      <div class="transfer-decision__date-wrapper">
        <p class="transfer-decision__field-title">Дата переноса</p>
                        <DatePicker
                            v-model="transferForm.date"
                            mode="date"
                            :masks="masks"
                            :popover="popover"
                            class="transfer-decision__date-picker"
                        >
                            <template v-slot="{ togglePopover, inputValue, inputEvents }">
                                <div>
                                    <div
                                        class="transfer-decision__date-inp-wrapper"
                                        :class="{'transfer-decision__date-inp-wrapper_disabled': false, 'transfer-decision__date-inp-wrapper_error': !!fieldsErrors.date}">
                                        <input
                                            class="transfer-decision__date-inp"
                                            placeholder="Выберите дату*"
                                            v-mask="'00.00.0000'"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                        />

                                        <div @click="() => togglePopover()"
                                             class="transfer-decision__date-inp-icon transfer-decision__date-inp-icon_active">
                                            <img src="@/assets/images/icons/calendar.svg" alt="calendar">
                                        </div>
                                    </div>

                                    <p class="transfer-decision__date-inp-error" v-if="!!fieldsErrors.date">Заполните
                                        обязательное поле</p>
                                </div>
                            </template>
                        </DatePicker>
                    </div>
                    <div class="transfer-decision__select-wrapper">
                      <p class="transfer-decision__field-title">Причина переноса</p>
                        <VSelect
                            placeholder="Выберите причину*"
                            :options="reasonOptions"
                            :error="!!fieldsErrors.reason"
                            v-model="transferForm.reason"
                            class="transfer-decision__select"
                        />
                        <p class="transfer-decision__field-error" v-if="!!fieldsErrors.reason">Заполните обязательное
                            поле</p>
                    </div>
                <div class="transfer-decision__textarea-wrapper">
                    <!-- <VCustomInput
                        placeholder="Комментарий"
                        :error="!!transferForm.commentary"
                        v-model="transferForm.commentary"
                        class="transfer-decision__inp"
                    /> -->
                    <VTextarea
            class="transfer-decision__textarea"
            :placeholder="commentaryPlaceholderText"
            :error="!!fieldsErrors.commentary"
            v-model="transferForm.commentary"
        />
                    <p class="transfer-decision__field-error" v-if="!!fieldsErrors.commentary">Заполните обязательное поле</p>
                </div>
    </div>
    <div class="transfer-decision__footer">
      <VButton class="transfer-decision__submit-btn" label="Перенести решение" @click="submitTransfer" />
      <VButton class="transfer-decision__cancel-btn" label="Отменить" @click="clickCancel" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import {DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css';
const props = defineProps({
  employees: {
    type: Array
  },
  selectedEmployeesList: {
    type: Array
  },
  data: {
    type: Object
  }
})

const emit = defineEmits(["submit","close", "cancel"]);

const transferForm = ref({
  date: '',
  reason: '',
  commentary: ''
})

const fieldsErrors = ref({
  date: '',
  reason: '',
  commentary: ''
})

const isformDirty = ref(false)
const reasonOptions = ref([{id:1,label:'Кандидат не выходит на связь'},{id: 2, label:'По инициативе кандидата'},{id: 3, label: 'Другая причина'}])
const masks = {
    input: 'DD.MM.YYYY',
}
const popover = ref({
    visibility: '',
    placement: 'bottom',
});

const isCommentaryRequired = computed(() => transferForm.value.reason === reasonOptions.value[2])
const commentaryPlaceholderText = computed(() => transferForm.value.reason === reasonOptions.value[2] ? 'Комментарий*' : 'Комментарий')
const clickCancel = () => {
  emit('cancel', props)
  emit("close")
}

const validateFields = () => {
  if(transferForm.value.date.length < 6){
        fieldsErrors.value.date = 'Заполните обязательное поле'
    }else {
        fieldsErrors.value.date = ''
    }
    if(transferForm.value.reason.length < 1){
        fieldsErrors.value.reason = 'Заполните обязательное поле'
    }else {
        fieldsErrors.value.reason = ''
    }
    if(transferForm.value.commentary.length < 1 && isCommentaryRequired.value){
        fieldsErrors.value.commentary = 'Заполните обязательное поле'
    }else {
        fieldsErrors.value.commentary = ''
    }


    return !Object.values(fieldsErrors.value).some((val) => !!val)
}
const submitTransfer = () => {
  isformDirty.value = true
  const isFieldsValid = validateFields()
  if(!isFieldsValid){
    return
  }
  emit('submit',transferForm.value)
}

watch(transferForm, () => {
  if(isformDirty.value)validateFields();  
},{deep:  true}
)
</script>

<style lang="scss" scoped>
.transfer-decision {
  position: relative;
  padding: 28.5px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;

  }

  &__subtitle {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;

  }

  &__main {
    flex: 1 1 auto;
    width: 100%;
  }

  &__field-title {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

    &__date-inp-wrapper {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        border-radius: 8px;
        background-color: $light;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > .transfer-decision__date-inp{
          &::placeholder {
          color: $dark-blue;
          opacity: 1;
          }
        }

        &_disabled {
            position: relative;
            opacity: 0.3;
            user-select: none;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 4;
            }
        }

        &_error {
            border: 1px solid #DD4646;
        }
    }

    &__date-inp {
        width: 100%;
        height: 100%;
        font-size: 14px;
        background-color: transparent;

        &::placeholder {
            color: $dark-blue;
        }

        &_disabled {
            &::placeholder {
                opacity: .3;
            }
        }
    }

    &__date-inp-wrapper_disabled &__date-inp {
        opacity: 0.3;
        cursor: no-drop;
        user-select: none;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 4;
        }
    }

    &__date-inp-error {
        margin-top: 4px;
        font-size: 14px;
        color: #DD4646;
    }

    &__select-wrapper {
        margin-top: 16px;
    }

    &__select {
        width: 100%;

        &::v-deep(.v-select__header-arrow) {
            margin-right: 8px;
        }

        &::v-deep(.v-select__header-palceholder) {
            color: $dark-blue;
        }
    }

    &__textarea {
      margin-top: 16px;
      height: 130px;
      border: none;
      color: $dark-blue;
    }

    &__field-error {
        margin-top: 4px;
        font-size: 14px;
        color: $red;
    }

  &__footer {
    margin-top: 24px;
    display: flex;
    gap: 12px;
    width: 100%;

    &>div {
      flex: 1 1 100%;
    }
  }

  &__cancel-btn {
    background-color: $light-gray-1 !important;
    color: $dark !important;
  }

}
</style>